
	.settings-privacy,
	.settings-fallback-privacy,
	.settings-client-data {
		.headline-container {
			display: flex;
			justify-content: space-between;
		}
		.label {
			margin-bottom: 8px;
		}
	}
	
	.settings-content {
		.label {
			margin-bottom: 8px;
		}
	}
	
	.settings-styles {
		.ant-input-number,
		.ant-input-number-group-wrapper {
			width: 100%;
		}
	}
	
	.settings-change-client {
		.clients {
			max-width: 1100px;
			width: 90%;
			margin: 80px auto 0px auto;
		}
	}