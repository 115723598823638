
.language-switcher {
	display: flex;
	align-items: center;
	.language {
		cursor: pointer;
		margin-right: 13px;
		&.active {
			opacity: 0.45;
			cursor: default;
		}
	}
}