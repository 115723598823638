
	@import 'styles/config.scss';
	
	.offer-landingpage {
		.button1 {
			@include fluid-type-var('font-size', #{var(--button1_fontSizeMobile)}, #{var(--button1_fontSizeDesktop)}, #{var(--button1_fontSizeMobile_calc)}, #{var(--button1_fontSizeDesktop_calc)}, 650, 1600);
			@include fluid-type-var('line-height', #{var(--button1_lineHeightMobile)}, #{var(--button1_lineHeightDesktop)}, #{var(--button1_lineHeightMobile_calc)}, #{var(--button1_lineHeightDesktop_calc)}, 650, 1600);
			@include fluid-type('padding-top', 7, 15, 650, 1600);
			@include fluid-type('padding-right', 16, 28, 650, 1600);
			@include fluid-type('padding-bottom', 7, 15, 650, 1600);
			@include fluid-type('padding-left', 16, 28, 650, 1600);
			@include fluid-type('border-radius', 3, 5, 650, 1600);
			
			display: inline-block;
			color: #{var(--button1_color)};
			font-family: #{var(--button1_fontFamily)};
			text-transform: #{var(--button1_textTransform)};
			letter-spacing: calc((#{var(--button1_letterSpacing)} * #{var(--button1_fontSizeDesktop)}) / 1000 + 0px);
			text-decoration: none;
			background-color: #{var(--button1_bg)};
			transition: background-color .3s linear;
			cursor: pointer;
			
			&.small {
				@include fluid-type('padding-top', 7, 11, 650, 1600);
				@include fluid-type('padding-right', 16, 28, 650, 1600);
				@include fluid-type('padding-bottom', 7, 11, 650, 1600);
				@include fluid-type('padding-left', 16, 28, 650, 1600);
			}
			
			&.disabled {
				pointer-events: none;
			}
			
			@media (min-width: $tablet) {
				&:hover {
					text-decoration: none;
					background-color: #{var(--button1_bg_hover)};
				}
			}
		}
		.link-text {
			cursor: pointer;
			text-decoration: underline;
		}
	}