
	@import 'styles/config.scss';
	
	.teaser-t1 {
		padding-top: 75px;
		@include fluid-type('padding-bottom', 25, 32, 650, 1600);
		
		.centercontent1047 {
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			
			.image,
			.content {
				width: aew-pr(473,1047);
				
				@media (max-width: $mobile) {
					width: 100%;
				}
			}
			
			.image {
				@media (max-width: $mobile) {
					+ .content {
						margin-top: 22px;
						text-align: center;
					}
				}
				
				img {
					border: 0px;
					max-width: 100%;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.content {
				.headline {
					+ .text {
						@include fluid-type('margin-top', 22, 32, 650, 1600);
					}
				}
				
				.text {
					ul {
						display: inline-block;
					}
				}
			}
		}
	}