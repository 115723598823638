
	@import 'styles/config.scss';
	
	.module-booking-t1 {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 20;
		.bg {
			position: absolute;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.6);
			z-index: 5;
			cursor: pointer;
		}
		.scroll-container {
			height: 100%;
			overflow-x: auto;
			position: relative;
			z-index: 7;
			.container {
				position: relative;
				z-index: 7;
				margin-top: 30px;
				margin-bottom: 30px;
				background-color: #{var(--bgweb_backgroundColor)};
				.close {
					position: absolute;
					right: 35px;
					top: 35px;
					width: 35px;
					height: 35px;
					cursor: pointer;
					&:before {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						width: 30px;
						height: 1px;
						background-color: #{var(--body_color)};
						transform: translate(-50%,-50%) rotate(45deg) scale(1.2);
						@media (max-width: 1024px) {
							width: 16px;
						}
					}
					&:after {
						content: '';
						position: absolute;
						left: 50%;
						top: 50%;
						width: 30px;
						height: 1px;
						background-color:#{var(--body_color)};
						transform: translate(-50%,-50%) rotate(-45deg) scale(1.2);
						@media (max-width: 1024px) {
							width: 16px;
						}
					}
				}
				.inner {
					padding-top: 100px;
					padding-bottom: 100px;
					.intro {
						text-align: center;
						.headline {
							margin-top: 5px;
						}
					}
					.room {
						margin-top: 65px;
						.desc {
							text-align: center;
						}
						.cont {
							display: flex;
							margin-top: 40px;
							@media (max-width: 1024px) {
								flex-wrap: wrap;
							}
							.image {
								width: aew-pr(459,1046);
								margin-right: aew-pr(77,1046);
								@media (max-width: 1024px) {
									width: 100%;
									margin-right: 0px;
								}
								img {
									max-width: 100%;
								}
							}
							.text-container {
								width: aew-pr(510,1046);
								@media (max-width: 1024px) {
									width: 100%;
									margin-top: 45px;
								}
							}
						}
					}
					.content {
						margin-top: 78px;
						.storno {
							display: flex;
							align-items: center;
							.headline {
								margin-left: 11px;
							}
						}
						.block {
							margin-top: 46px;
							.header {
								padding: 10px 37px;
								border-radius: 5px;
								color: #{var(--button1_color)};
								background-color: #{var(--button1_bg)};
								font-family: #{var(--button1_fontFamily)};
								text-transform: #{var(--button1_textTransform)};
								letter-spacing: calc((#{var(--button1_letterSpacing)} * #{var(--button1_fontSizeDesktop)}) / 1000 + 0px);
								@include fluid-type-var('font-size', #{var(--button1_fontSizeMobile)}, #{var(--button1_fontSizeDesktop)}, #{var(--button1_fontSizeMobile_calc)}, #{var(--button1_fontSizeDesktop_calc)}, 650, 1600);
								@include fluid-type-var('line-height', #{var(--button1_lineHeightMobile)}, #{var(--button1_lineHeightDesktop)}, #{var(--button1_lineHeightMobile_calc)}, #{var(--button1_lineHeightDesktop_calc)}, 650, 1600);
								@media (max-width: 650px) {
									padding: 10px 15px;
								}
							}
							.data {
								border-radius: 5px;
								padding: 30px 37px;
								margin-top: 5px;
								@media (max-width: 650px) {
									padding: 15px;
								}
								.company-fields {
									margin-top: 25px;
								}
							}
						}
						.privacy {
							margin-top: 30px;
						}
						.submit {
							margin-top: 50px;
							text-align: center;
						}
					}
				}
			}
		}
		form {
			.ant-form-item {
				label {
					color: #{var(--body_color)};
					font-family: #{var(--body_fontFamily)};
					&:before {
						color: #{var(--body_color)} !important;
						font-family: #{var(--body_fontFamily)} !important;
					}
					&:hover {
						.ant-checkbox-inner {
							border-color: #{var(--button1_bg)} !important;
						}
						.ant-checkbox-checked {
							opacity: 0.75;
							.ant-checkbox-inner {
								border-radius: 2px !important;
								border-color: #{var(--button1_bg)} !important;
								background-color: #{var(--button1_bg)} !important;
							}
							&:after {
								border-color: #{var(--button1_bg)} !important;
							}
						}
					}
				}
				.ant-input,
				.ant-select-selector {
					border-radius: 2px;
					border-color: #{var(--body_color)};
					&:hover {
						border-color: #{var(--body_color)};
					}
					&:focus {
						box-shadow: none;
					}
				}
				.ant-select {
					&:hover {
						.ant-select-selector {
							border-color: #{var(--body_color)} !important;
						}
					}
					&.ant-select-focused {
						.ant-select-selector {
							box-shadow: none !important;
							border-color: #{var(--body_color)} !important;
						}
					}
					.ant-select-arrow {
						.anticon {
							& > svg {
								color: #{var(--body_color)};
							}
						}
					}
				}
				.ant-checkbox-checked {
					.ant-checkbox-inner {
						border-radius: 2px;
						border-color: #{var(--button1_bg)} !important;
						background-color: #{var(--button1_bg)};
					}
					&:after {
						border-color: #{var(--button1_bg)};
					}
				}
			}
		}
	}