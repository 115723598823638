
	@import 'styles/config.scss';
	
	.text-t1 {
		@include fluid-type('padding-top', 70, 110, 650, 1600);
		
		+ .teaser-t2 {
			@include fluid-type('padding-top', 70, 110, 650, 1600);
		}
		
		.container {
			@include fluid-type('padding-top', 40, 80, 650, 1600);
			@include fluid-type('padding-bottom', 40, 80, 650, 1600);
			
			@media (min-width: $mobile) {
				border-radius: 10px;
			}
			
			.text-container {
				text-align: center;
				
				.text {
					@include fluid-type('margin-top', 13, 25, 650, 1600);
				}
				
				.value {
					margin-top: 25px;
				}
				
				.map {
					@include fluid-type('margin-top', 50, 100, 650, 1600);
					padding-top: aew-pr(9,16);
					position: relative;
					
					iframe {
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
					}
				}
				
				+ .text-container {
					@include fluid-type('padding-top', 50, 100, 650, 1600);
				}
			}
		}
	}