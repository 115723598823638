
	@import 'styles/config.scss';
	
	.room-t1 {
		.container {
			@include fluid-type('padding-top', 30, 60, 650, 1600);
			@include fluid-type('padding-bottom', 30, 60, 650, 1600);
			
			@media (min-width: $mobile) {
				border-radius: 10px;
			}
			
			.element {
				.content {
					text-align: center;
					
					&.top {
						.headline,
						.text {
							margin-top: 17px;
						}
					}
					
					&.bottom {
						@include fluid-type('margin-top', 40, 80, 650, 1600);
						
						.text {
							text-align: left;
						}
						
						.price-table-title {
							text-align: left;
							@include fluid-type('padding-left', 0, 30, 650, 1600);
							@include fluid-type('padding-right', 0, 30, 650, 1600);
							@include fluid-type('margin-top', 50, 100, 650, 1600);
						}
						
						.price-table {
							border-radius: 5px;
							margin-top: 15px;
							background-color: #{var(--bgweb_backgroundColor)};
							
							&-inner {
								padding: 30px;
							}
						}
					}
				}
				
				.gallery {
					margin-top: 40px;
					position: relative;
					.sketch {
						position: absolute;
						left: 50%;
						bottom: 0px;
						transform: translate(-50%, 50%);
						z-index: 5;
					}
					.fullscreen {
						position: absolute;
						left: 20px;
						bottom: 20px;
						z-index: 5;
						cursor: pointer;
					}
					.lg-react-element {
						display: none;
					}
					.splide {
						&__slide {
							.image {
								height: 0px;
								padding-top: aew-pr(322,464);
								position: relative;
								img {
									border: 0px;
									max-width: 100%;
									display: block;
									position: absolute;
									left: 0px;
									top: 0px;
									width: 100%;
									height: 100%;
									object-fit: cover;
								}
							}
						}
						
						&__arrow {
							@include fluid-type('height', 23, 35, 650, 1600);
							@include fluid-type('width', 10, 15, 650, 1600);
							background-color: transparent;
							border-radius: 0;
							opacity: 1;
							@media (min-width: 1025px) {
								&:hover {
									opacity: 0.75;
								}
							}
							svg {
								width: 100%;
								height: 100%;
								color: #FFFFFF;
							}
							
							&--prev {
								@include fluid-type('left', 12, 20, 650, 1600);
							}
							&--next {
								@include fluid-type('right', 12, 20, 650, 1600);
							}
						}
					}
				}
			}
		}
	}
	
	.lg-container {
		.lg-download {
			display: none;
		}
		.lg-outer img.lg-object {
			max-height: calc(100vh - 150px);
		}
	}