
	@import 'styles/config.scss';
	
	.price-table {
		@media (max-width: $tablet) {
			overflow: auto;
		}
		&-inner {
			text-align: left;
			
			.headlines {
				display: flex;
				justify-content: space-between;
				padding: 15px 0px;
				border-bottom: 1px solid #{var(--body_color)};
				@media (max-width: 950px) {
					display: none;
				}
			}
			.prices {
				.price {
					display: flex;
					justify-content: space-between;
					padding: 15px 0px;
					@media (max-width: 950px) {
						flex-wrap: wrap;
					}
					& + .price {
						border-top: 1px solid #{var(--body_color)};
					}
				}
			}
			.total-price {
				display: flex;
				justify-content: space-between;
				padding: 15px 0px;
				border-top: 1px solid #{var(--body_color)};
			}
			.label {
				display: none;
				@media (max-width: 950px) {
					display: block;
					margin-right: 7px;
				}
			}
			.title {
				width: 350px;
				@media (max-width: 950px) {
					width: 100%;
					margin-bottom: 10px;
				}
			}
			.right {
				display: flex;
				@media (max-width: 950px) {
					width: 100%;
					flex-wrap: wrap;
				}
				.quantity {
					width: 130px;
					@media (max-width: 950px) {
						width: 100%;
					}
				}
				.singlePrice {
					width: 130px;
					display: flex;
					align-items: center;
					@media (max-width: 950px) {
						width: 100%;
					}
					.discount {
						display: inline-block;
						margin-left: 8px;
						color: green;
						font-size: 12px;
					}
					&.with-discount {
						span {
							text-decoration: line-through;
							&.discount {
								text-decoration: none;
							}
						}
					}
				}
				.totalPrice {
					width: 130px;
					text-align: right;
					justify-content: flex-end;
					@media (max-width: 950px) {
						width: 100%;
						text-align-last: left;
						justify-content: space-between;
					}
				}
			}
		}
	}