
	.settings-content-usp {
		.headline-container {
			display: flex;
			justify-content: space-between;
		}
		.label {
			margin-bottom: 8px;
		}
		.text-editor {
			margin-bottom: 24px;
		}
	}