
	.bookings-list {
		table {
			tr {
				td {
					button {
						padding: 0px;
					}
				}
			}
		}
	}
	
	.bookings-detail {
		.headline-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.right-content {
				display: flex;
				align-items: center;
				span {
					display: inline-block;
					margin-left: 8px;
				}
				.flag-container {
					margin-left: 10px;
				}
			}
		}
		.ant-col {
			margin-top: 25px;
			.ant-card-head {
				background: rgba(217, 217, 217, 0.2);
			}
		}
		.price-table {
			.singlePrice {
				display: flex;
				align-items: center;
				.discount {
					display: inline-block;
					margin-left: 8px;
					color: green;
					font-size: 12px;
				}
				&.with-discount {
					span {
						text-decoration: line-through;
						&.discount {
							text-decoration: none;
						}
					}
				}
			}
			.total-price {
				margin-top: 20px;
			}
		}
	}
	
	.request-offer-drawer {
		.ant-input-number-group-wrapper,
		.ant-picker {
			width: 100%;
		}
		.ant-drawer-header {
			height: 65px;
			.ant-drawer-header-title {
				height: 32px;
			}
			.ant-space {
				height: 32px;
			}
		}
	}