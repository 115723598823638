	
	@import 'functions.scss';
	
	body {
		&.hidden {
			overflow: hidden;
		}
		.lg-container {
			.lg-backdrop.in {
				opacity: 0.7;
			}
			.lg-counter {
				color: #FFFFFF;
			}
			.lg-next,
			.lg-prev {
				background-color: #000;
				color: #FFF;
			}
			.lg-toolbar .lg-icon {
				color: #FFF;
			}
		}
		@media (max-width: 1024px) {
			.mobile-alert + .ant-layout {
				display: none !important;
			}
		}
		.mobile-alert {
			position: fixed;
			left: 0px;
			top: 0px;
			width: 100%;
			height: 100%;
			background-color: #FFF;
			z-index: 100000;
			display: none;
			@media (max-width: 1024px) {
				display: block;
			}
			.inner {
				max-width: 600px;
				font-weight: bold;
				width: 90%;
				text-align: center;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 10;
				font-size: 16px;
				line-height: 24px;
				color: #000;
			}
		}
	}
	
	header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-inline: 40px !important;
		button {
			background-color: transparent;
			border: 0px;
			display: flex;
			align-items: center;
			height: 40px;
			.ant-avatar {
				cursor: pointer;
			}
		}
	}
	
	.ant-btn-primary {
		box-shadow: none !important;
	}
	
	.ant-dropdown {
		button {
			color: rgba(0, 0, 0, 0.88);
			padding: 0px !important;
			&:hover {
				color: rgba(0, 0, 0, 0.88) !important;
			}
		}
	}
	
	.ant-spin {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 100;
		background-color: #FFF;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	
	.ant-layout-has-sider {
		flex-direction: initial !important;
	}
	
	.site-layout {
		width: calc(100% - 250px) !important;
		margin-left: 250px;
		transition: all 0.2s;
	}
	
	.ant-layout-sider-collapsed {
		& + .site-layout {
			margin-left: 80px;
		}
	}
	
	.sticky-save-container {
		.content {
			padding: 13px 0px;
			background-color: #FFFFFF;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.ant-form-item {
				margin-bottom: 0px;
			}
			button {
				& + a {
					margin-left: 20px;
				}
			}
		}
		.ant-affix {
			.content {
				border-top: 1px solid #d9d9d9;
			}
		}
	}
	
	.color-error {
		color: #ff4d4f;
	}
	
	.ant-upload-list.ant-upload-list-picture-card .ant-upload-list-item {
		background-color: #DDD;
		&:before {
			width: 100% !important;
			height: 100% !important;
			left: 0px;
			border-radius: 8px;
		}
	}
	
	.df {
		display: flex;
	}
	
	.jb {
		justify-content: space-between;
	}
	
	.ant-select-dropdown {
		.rc-virtual-list{
			.ant-select-item {
				&.available {
					color: green;
				}
				&.notAvailable {
					color: red;
				}
			}
		}
	}
	