
	.text-editor {
		.tox-statusbar__branding {
			display: none;
		}
		div[title="indentation"],
		div[title="history"] {
			display: none;
		}
		.multi-lang-text-editor {
			& > div {
				display: none;
				&.active {
					display: block;
				}
			}
		}
	}