
	@import 'styles/config.scss';
	
	.module-intro-t1 {
		@include fluid-type('padding-top', 80, 140, 650, 1600);
		@include fluid-type('padding-bottom', 65, 90, 650, 1600);
		text-align: center;
		
		.text {
			@include fluid-type('margin-top', 13, 25, 650, 1600);
		}
	}