
	.request-list {
		.headline-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		table {
			tr {
				td {
					button {
						padding: 0px;
					}
					.state {
						.anticon {
							margin-left: 7px;
						}
					}
				}
			}
		}
	}
	
	.request-detail {
		.headline-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
			.right-content {
				display: flex;
				align-items: center;
				span {
					display: inline-block;
					margin-left: 8px;
				}
				.flag-container {
					margin-left: 10px;
				}
			}
		}
		.ant-col {
			margin-top: 25px;
			.ant-card-head {
				background: rgba(217, 217, 217, 0.2);
			}
		}
		.request-overview {
			.ant-col {
				margin-top: 0px;
			}
			.price-table {
				.headlines {
					padding: 5px 0px;
					border-bottom: 1px solid rgba(217, 217, 217, 0.7);
				}
				.prices {
					.price {
						padding: 5px 0px;
						& + .price {
							border-top: 1px solid rgba(217, 217, 217, 0.7);
						}
					}
				}
				.total-price {
					padding: 5px 0px;
					border-top: 1px solid rgba(217, 217, 217, 0.7);
					.headline,
					.value {
						font-weight: bold;
					}
				}
			}
		}
	}
	
	.request-offer-drawer {
		.ant-input-number-group-wrapper,
		.ant-picker {
			width: 100%;
		}
		.ant-drawer-header {
			height: 65px;
			.ant-drawer-header-title {
				height: 32px;
			}
			.ant-space {
				height: 32px;
			}
		}
		.label {
			margin-bottom: 8px;
		}
		.ant-space {
			display: flex;
			.ant-space-item {
				text-align: center;
				.label {
					text-align: left;
				}
				button {
					margin: 0px auto;
				}
			}
		}
		.asa-prices {
			display: flex;
			border: 1px solid rgba(217, 217, 217, 0.7);
			border-radius: 8px;
			text-align: left;
			.dates,
			.rateplan {
				width: 150px;
				border-right: 1px solid rgba(217, 217, 217, 0.7);
				& > div:first-child {
					border-bottom: 1px solid rgba(217, 217, 217, 0.7);
				}
				& > div {
					padding: 4px 8px;
				}
				.total {
					border-top: 1px solid rgba(217, 217, 217, 0.7);
				}
			}
		}
	}
	
	.request-create {
		.ant-input-number-group-wrapper,
		.ant-picker {
			width: 100%;
		}
	}