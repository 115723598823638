
	@import 'styles/config.scss';
	
	.module-footer-t1 {
		padding-top: aew-pr(126,1920);
		padding-bottom: 80px;
		@media (max-width: 800px) {
			padding-top: 105px;
			padding-bottom: 60px;
		}
		.inner {
			@media (max-width: 800px) {
				flex-wrap: wrap;
			}
			.socials {
				display: flex;
				flex-direction: column;
				align-items: center;
				width: aew-pr(204,1470);
				@media (max-width: 800px) {
					width: 100%;
					order: 2;
					margin-top: 45px;
					flex-direction: row;
					justify-content: center;
				}
				a {
					& + a {
						margin-top: 65px;
						@media (max-width: 800px) {
							margin-top: 0px;
							margin-left: 60px;
						}
					}
				}
			}
			.logo {
				width: aew-pr(475,1470);
				display: flex;
				justify-content: flex-end;
				@media (max-width: 800px) {
					width: 100%;
					order: 1;
				}
				img {
					max-width: 215px;
					@media (max-width: 800px) {
						margin: 0px auto;
						max-width: 130px;
					}
				}
			}
			.client-data {
				width: aew-pr(702,1470);
				margin-left: aew-pr(98,1470);
				@media (max-width: 800px) {
					width: 100%;
					margin-left: 0px;
					margin-top: 45px;
					text-align: center;
					order: 3;
				}
				.mid {
					margin-top: 35px;
				}
				.bottom {
					@include fluid-type('margin-top', 40, 70, 650, 1600);
				}
			}
		}
		.credits {
			margin-top: 80px;
			@media (max-width: 650px) {
				margin-top: 40px;
				flex-direction: column;
				align-items: center;
			}
			.link-container {
				& + .link-container {
					margin-left: 20px;
					@media (max-width: 650px) {
						margin-left: 0px;
						margin-top: 20px;
					}
				}
			}
			.created {
				margin-left: 30px;
				@media (max-width: 650px) {
					margin-left: 0px;
					margin-top: 35px;
				}
				svg {
					margin-left: 6px;
				}
			}
		}
	}