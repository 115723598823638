
	@import 'styles/config.scss';
	
	.teaser-t2 {
		@include fluid-type('padding-top', 25, 32, 650, 1600);
		@include fluid-type('padding-bottom', 50, 100, 650, 1600);
		
		.centercontent1470 {
			@media (max-width: $mobile) {
				max-width: 100% !important;
				width: 100% !important;
			}
		}
		
		.gallery {
			.splide__track {
				@media (max-width: $mobile) {
					padding-left: 14px !important;
					padding-right: 64px !important;
				}
			}
		}
		
		.element {
			.image {
				img {
					border: 0px;
					max-width: 100%;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				
				+ .content {
					margin-top: 14px;
				}
			}
			
			.content {
				.toggle {
					button {
						appearance: none;
						border: none;
						background: transparent;
						padding: 0;
						margin: 0;
						cursor: pointer;
						
						display: flex;
						align-items: center;
						color: #{var(--button1_bg)};
						
						&.open {
							.toggle-icon {
								transform: rotate(180deg);
							}
						}
						
						.toggle-text {
							pointer-events: none;
						}
						
						.toggle-icon {
							margin-left: 15px;
							height: 18px;
							width: 12px;
							pointer-events: none;
							transition: all .3s ease-in-out;
							
							@media (max-width: $mobile) {
								height: 16px;
								width: 10px;
							}
							
							svg {
								display: block;
								width: 100%;
								height: 100%;
							}
						}
					}
				}
				
				.text {
					@include fluid-type('margin-top', 10, 20, 650, 1600);
					display: none;
					
					&.open {
						display: block;
					}
				}
			}
		}
	}