
	@import 'styles/config.scss';
	
	.module-header-small-t1 {
		padding-top: 60px;
		padding-bottom: 60px;
		position: relative;
		
		.logo {
			position: absolute;
			left: 60px;
			top: 15px;
			z-index: 5;
			
			@media (max-width: $tablet) {
				left: 20px;
			}
			
			img {
				max-height: 90px;
			}
		}
		
		.tel {
			position: absolute;
			right: 60px;
			top: 50%;
			z-index: 5;
			cursor: pointer;
			transform: translateY(-50%);
			
			@media (max-width: $tablet) {
				transform: none;
				right: 20px;
				top: 12px;
				
				svg {
					width: 22px;
					height: 22px;
				}
			}
			
			.number {
				margin-left: 20px;
				
				@media (max-width: $tablet) {
					display: none;
				}
			}
		}
	}