
	@import 'styles/config.scss';
	
	.credits-t1 {
		margin-top: 40px;
		
		.container {
			@include fluid-type('padding-top', 40, 80, 650, 1600);
			@include fluid-type('padding-bottom', 40, 80, 650, 1600);
			
			@media (min-width: $mobile) {
				border-radius: 10px;
			}
			
			.text-container {
				text-align: center;
				.content {
					@include fluid-type('margin-top', 13, 25, 650, 1600);
					.country {
						margin-bottom: 20px;
					}
				}
			}
		}
	}