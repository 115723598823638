
	@import 'styles/config.scss';
	
	.variant {
		display: flex;
		align-items: baseline;
		
		&:before {
			content: '';
			@include fluid-type('width', 45, 65, 650, 1600);
			@include fluid-type('margin-right', 11, 17, 650, 1600);
			background-color: currentColor;
			display: block;
			height: 2px;
		}
	}