
	@function aew-pr($width, $maxwidth) {
		@if aew-is-number($width) and aew-is-number($maxwidth) {
			@return ((100*$width)/$maxwidth) + 0%;
		}
	}
	
	@function ls($unit, $base) {
		@return ($unit * $base) / 1000 + 0px;
	}
	
	@function strip-unit($number) {
		@if type-of($number) == 'number' and not unitless($number) {
			@return $number / ($number * 0 + 1);
		}
		@return $number;
	}
	
	@mixin fluid-type-var($property, $min-font-size, $max-font-size, $min-font-size-calc, $max-font-size-calc, $min-vw, $max-vw) {
		$min-font-size-px: $min-font-size;
		$max-font-size-px: $max-font-size;
		$min-vw-px: $min-vw + 0px;
		$max-vw-px: $max-vw + 0px;
		
		& {
			#{$property}: $min-font-size-px;
			@media (min-width: $min-vw-px) {
				#{$property}: calc(#{$min-font-size-px} + (#{$max-font-size-calc} - #{$min-font-size-calc}) * ((100vw - #{$min-vw-px}) / (#{$max-vw} - #{$min-vw})));
			}
			@media (min-width: $max-vw-px) {
				#{$property}: $max-font-size-px;
			}
		}
	}
	
	@mixin fluid-type($property, $min-font-size, $max-font-size, $min-vw, $max-vw) {
		$min-font-size-px: $min-font-size + 0px;
		$max-font-size-px: $max-font-size + 0px;
		$min-vw-px: $min-vw + 0px;
		$max-vw-px: $max-vw + 0px;
		
		& {
			#{$property}: $min-font-size-px;
			@media (min-width: $min-vw-px) {
				#{$property}: calc(#{$min-font-size-px} + (#{$max-font-size} - #{$min-font-size}) * ((100vw - #{$min-vw-px}) / (#{$max-vw} - #{$min-vw})));
			}
			@media (min-width: $max-vw-px) {
				#{$property}: $max-font-size-px;
			}
		}
	}