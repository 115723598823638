
	.login-wrapper {
		position: absolute;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #001529;
		form.login-form {
			max-width: 300px;
			.login-form-button {
				width: 100%;
			}
			.error-container {
				margin-bottom: 15px;
				margin-top: -15px;
			}
		}
	}
	