
	@import 'styles/config.scss';
	
	.module-variants-t1 {
		.centercontentlargest {
			>.container {
				@include fluid-type('padding-top', 40, 87, 650, 1600);
				@include fluid-type('padding-bottom', 40, 80, 650, 1600);
				
				@media (min-width: $mobile) {
					border-radius: 10px;
				}
			}
		}
		.module-intro-t1 {
			padding-top: 0;
			padding-bottom: 0;
			
			+ .variants {
				margin-top: 30px;
			}
		}
		
		.variants {
			.centercontent1470 {
				@media (max-width: $mobile) {
					max-width: 100%;
					width: 100%;
				}
			}
			
			.gallery {
				.splide__track {
					@media (max-width: $mobile) {
						padding-left: 14px !important;
						padding-right: 64px !important;
					}
				}
				
				.splide:not( .is-overflow ) .splide__list {
					justify-content: center;
				}
				
				.splide:not( .is-overflow ) .splide__slide:last-child {
					margin: 0 !important;
				  }
				
				.element {
					.variant {
						margin-bottom: 12px;
					}
					
					.image {
						height: 0px;
						padding-top: aew-pr(322,464);
						position: relative;
						img {
							border: 0px;
							max-width: 100%;
							display: block;
							position: absolute;
							left: 0px;
							top: 0px;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
					
					.date {
						font-weight: bold;
					}
					
					.content {
						margin-top: 20px;
						
						.price {
							margin-top: 10px;
						}
						
						.link-container {
							margin-top: 10px;
						}
					}
				}
			}
		}
	}