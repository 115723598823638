
	@import 'styles/config.scss';
	
	.offer-landingpage {
		@include fluid-type-var('font-size', #{var(--body_fontSizeMobile)}, #{var(--body_fontSizeDesktop)}, #{var(--body_fontSizeMobile_calc)}, #{var(--body_fontSizeDesktop_calc)}, 650, 1600);
		@include fluid-type-var('line-height', #{var(--body_lineHeightMobile)}, #{var(--body_lineHeightDesktop)}, #{var(--body_lineHeightMobile_calc)}, #{var(--body_lineHeightDesktop_calc)}, 650, 1600);
		color: #{var(--body_color)};
		font-family: #{var(--body_fontFamily)};
		text-transform: #{var(--body_textTransform)};
		letter-spacing: calc((#{var(--body_letterSpacing)} * #{var(--body_fontSizeDesktop)}) / 1000 + 0px);
		
		::-moz-selection { background: #{var(--body_color)}; color: #FFFFFF; }
		::selection { background: #{var(--body_color)}; color: #FFFFFF; }
		
		.centercontentlargest {
			max-width: calc(100% - 84px);
			margin-left: auto;
			margin-right: auto;
			
			@media (max-width: $tablet) {
				max-width: 90%;
			}
			@media (max-width: $mobile) {
				max-width: 100%;
			}
		}
		
		.center-content-large {
			width: calc(100% - 120px);
			margin-left: auto;
			margin-right: auto;
			
			@media (max-width: $tablet) {
				width: calc(100% - 40px);
			}
		}
		
		.centercontent1470 {
			max-width: 1470px;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			
			@media (max-width: $tablet) {
				max-width: 90%;
			}
		}
		
		.centercontent1047 {
			max-width: 1047px;
			width: 90%;
			margin-left: auto;
			margin-right: auto;
			
			@media (max-width: $tablet) {
				max-width: 90%;
			}
		}
		
		&.bgweb,
		.bgweb {
			background-color: #{var(--bgweb_backgroundColor)};
		}
		
		&.bgteaser,
		.bgteaser {
			background-color: #{var(--bgteaser_backgroundColor)};
		}
		
		&.bghighlight,
		.bghighlight {
			background-color: #{var(--bghighlight_backgroundColor)};
		}
		
		.t1Large {
			@include fluid-type-var('font-size', #{var(--t1Large_fontSizeMobile)}, #{var(--t1Large_fontSizeDesktop)}, #{var(--t1Large_fontSizeMobile_calc)}, #{var(--t1Large_fontSizeDesktop_calc)}, 650, 1600);
			@include fluid-type-var('line-height', #{var(--t1Large_lineHeightMobile)}, #{var(--t1Large_lineHeightDesktop)}, #{var(--t1Large_lineHeightMobile_calc)}, #{var(--t1Large_lineHeightDesktop_calc)}, 650, 1600);
			color: #{var(--t1Large_color)};
			font-family: #{var(--t1Large_fontFamily)};
			text-transform: #{var(--t1Large_textTransform)};
			letter-spacing: calc((#{var(--t1Large_letterSpacing)} * #{var(--t1Large_fontSizeDesktop)}) / 1000 + 0px);
		}
		
		.t2Large {
			@include fluid-type-var('font-size', #{var(--t2Large_fontSizeMobile)}, #{var(--t2Large_fontSizeDesktop)}, #{var(--t2Large_fontSizeMobile_calc)}, #{var(--t2Large_fontSizeDesktop_calc)}, 650, 1600);
			@include fluid-type-var('line-height', #{var(--t2Large_lineHeightMobile)}, #{var(--t2Large_lineHeightDesktop)}, #{var(--t2Large_lineHeightMobile_calc)}, #{var(--t2Large_lineHeightDesktop_calc)}, 650, 1600);
			color: #{var(--t2Large_color)};
			font-family: #{var(--t2Large_fontFamily)};
			text-transform: #{var(--t2Large_textTransform)};
			letter-spacing: calc((#{var(--t2Large_letterSpacing)} * #{var(--t2Large_fontSizeDesktop)}) / 1000 + 0px);
		}
		
		h1, .t1 {
			@include fluid-type-var('font-size', #{var(--h1_fontSizeMobile)}, #{var(--h1_fontSizeDesktop)}, #{var(--h1_fontSizeMobile_calc)}, #{var(--h1_fontSizeDesktop_calc)}, 650, 1600);
			@include fluid-type-var('line-height', #{var(--h1_lineHeightMobile)}, #{var(--h1_lineHeightDesktop)}, #{var(--h1_lineHeightMobile_calc)}, #{var(--h1_lineHeightDesktop_calc)}, 650, 1600);
			color: #{var(--h1_color)};
			font-family: #{var(--h1_fontFamily)};
			text-transform: #{var(--h1_textTransform)};
			letter-spacing: calc((#{var(--h1_letterSpacing)} * #{var(--h1_fontSizeDesktop)}) / 1000 + 0px);
		}
		
		h2, .t2 {
			@include fluid-type-var('font-size', #{var(--h2_fontSizeMobile)}, #{var(--h2_fontSizeDesktop)}, #{var(--h2_fontSizeMobile_calc)}, #{var(--h2_fontSizeDesktop_calc)}, 650, 1600);
			@include fluid-type-var('line-height', #{var(--h2_lineHeightMobile)}, #{var(--h2_lineHeightDesktop)}, #{var(--h2_lineHeightMobile_calc)}, #{var(--h2_lineHeightDesktop_calc)}, 650, 1600);
			color: #{var(--h2_color)};
			font-family: #{var(--h2_fontFamily)};
			text-transform: #{var(--h2_textTransform)};
			letter-spacing: calc((#{var(--h2_letterSpacing)} * #{var(--h2_fontSizeDesktop)}) / 1000 + 0px);
		}
		
		h3, .t3 {
			@include fluid-type-var('font-size', #{var(--h3_fontSizeMobile)}, #{var(--h3_fontSizeDesktop)}, #{var(--h3_fontSizeMobile_calc)}, #{var(--h3_fontSizeDesktop_calc)}, 650, 1600);
			@include fluid-type-var('line-height', #{var(--h3_lineHeightMobile)}, #{var(--h3_lineHeightDesktop)}, #{var(--h3_lineHeightMobile_calc)}, #{var(--h3_lineHeightDesktop_calc)}, 650, 1600);
			color: #{var(--h3_color)};
			font-family: #{var(--h3_fontFamily)};
			text-transform: #{var(--h3_textTransform)};
			letter-spacing: calc((#{var(--h3_letterSpacing)} * #{var(--h3_fontSizeDesktop)}) / 1000 + 0px);
		}
		
		a {
			color: #{var(--body_color)};
			text-decoration: none;
			@media (min-width: 1025px) {
				&:hover {
					text-decoration: underline;
				}
			}
		}
		
		p {
			a {
				text-decoration: underline;
			}
		}
		
		.df {
			display: flex;
		}
		
		.ac {
			align-items: center;
		}
		
		.jc {
			justify-content: center;
		}
		
		.jb {
			justify-content: space-between;
		}
		
		.fw {
			flex-wrap: wrap;
		}
		
		ul {
			margin: 0px;
			padding-left: 20px;
			text-align: left;
			
			li {
				list-style-type: disc;
			}
		}
	}