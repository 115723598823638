
	@import 'styles/config.scss';
	
	.module-header-t1 {
		padding-top: 60px;
		padding-bottom: 60px;
		@media (max-width: $tablet) {
			padding-top: 45px;
			padding-bottom: 20px;
		}
		.tel {
			position: absolute;
			right: 60px;
			top: 15px;
			z-index: 5;
			cursor: pointer;
			@media (max-width: $tablet) {
				right: 20px;
				top: 12px;
				svg {
					width: 22px;
					height: 22px;
				}
			}
			.number {
				margin-left: 20px;
				@media (max-width: $tablet) {
					display: none;
				}
			}
		}
		
		.contact-person {
			position: absolute;
			@include fluid-type('left', 40, 230, 320, 1600);
			@include fluid-type('width', 88, 210, 320, 1600);
			box-sizing: border-box;
			top: 0;
			z-index: 10;
			border-bottom-left-radius: 5px;
			border-bottom-right-radius: 5px;
			padding: 10px 12px 15px 12px;
			
			@media (min-width: $tablet) {
				&:hover {
					.text {
						&.short {
							display: none;
						}
						
						&.long {
							display: block;
						}
					}
				}
			}
			
			@media (max-width: $tablet) {
				&.active {
					.text {
						&.short {
							display: none;
						}
						
						&.long {
							display: block;
						}
					}
				}
			}
			
			.image {
				~ .text {
					margin-top: 15px;
				}
				
				img {
					border: 0px;
					max-width: 100%;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 50%;
				}
			}
			
			.text {
				text-align: center;
				filter: invert(1);
				
				&.long {
					display: none;
				}
				
				.item {
					filter: invert(1);
					
					&-whatsapp {
						margin-top: 28px;
						
						& + .item-phone,
						& + .item-email  {
							margin-top: 18px;
						}
						
						.icon {
							@include fluid-type('width', 35, 50, 650, 1600);
							@include fluid-type('height', 35, 50, 650, 1600);
							filter: invert(1);
						}
					}
				}
			}
		}
		.gallery {
			position: relative;
			height: calc(100vh - 120px);
			@media (max-width: $tablet) {
				height: calc(100vh - 80px);
			}
			.logo {
				position: absolute;
				left: 50%;
				top: 130px;
				transform: translateX(-50%);
				z-index: 5;
				svg,
				img {
					max-width: 220px;
					@media (max-width: 1024px) {
						max-width: 100px;
					}
				}
			}
			.splide {
				height: 100%;
				&__track {
					height: 100%;
				}
				&__slide {
					height: 100%;
					.image {
						position: relative;
						height: 100%;
						&:after {
							content: '';
							display: block;
							position: absolute;
							left: 0;
							z-index: 1;
							bottom: 0;
							width: 100%;
							height: 50%;
							background: linear-gradient(to bottom,  rgba(0,0,0,0) 0%,rgba(0,0,0,0.42) 100%);
						}
						
						img {
							border: 0px;
							max-width: 100%;
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				}
				
				&__arrow {
					@include fluid-type('height', 23, 35, 650, 1600);
					@include fluid-type('width', 10, 15, 650, 1600);
					background-color: transparent;
					border-radius: 0;
					opacity: 1;
					@media (min-width: 1025px) {
						&:hover {
							opacity: 0.75;
						}
					}
					svg {
						width: 100%;
						height: 100%;
						color: #FFFFFF;
					}
					
					&--prev {
						@include fluid-type('left', 15, 65, 650, 1600);
					}
					&--next {
						@include fluid-type('right', 15, 65, 650, 1600);
					}
				}
				.inner-text {
					@include fluid-type('padding-bottom', 70, 75, 650, 1600);
				}
				&__content {
					position: absolute;
					z-index: 5;
					text-align: center;
					bottom: 0px;
					width: 100%;
					pointer-events: none;
					
					.preheadline {
						@include fluid-type('margin-bottom', 10, 15, 650, 1600);
					}
					
					.link-container {
						display: flex;
						justify-content: center;
						pointer-events: auto;
						position: absolute;
						left: 50%;
						bottom: 0px;
						transform: translate(-50%, 50%);
						z-index: 5;
					}
				}
			}
		}
	}
	
	.sticky-header {
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 60px;
		z-index: 15;
		box-shadow: 0px 5px 18px 0px rgba(0,0,0,0.15);
		transform: translateY(-100%);
		transition: transform 0.2s ease-in-out;
		@media (max-width: $tablet) {
			height: 50px;
		}
		&.show {
			transform: translateY(0px);
		}
		.tel {
			position: absolute;
			right: 60px;
			top: 15px;
			z-index: 5;
			cursor: pointer;
			@media (max-width: $tablet) {
				right: 20px;
				top: 12px;
				svg {
					width: 22px;
					height: 22px;
				}
			}
			.number {
				margin-left: 20px;
				@media (max-width: $tablet) {
					display: none;
				}
			}
		}
	}