
	@import 'styles/config.scss';
	
	.module-contact-person-t1 {
		@include fluid-type('padding-top', 50, 100, 650, 1600);
		@include fluid-type('padding-bottom', 10, 30, 650, 1600);
		
		.centercontent1047 {
			flex-wrap: wrap;
		}
		
		.left {
			width: aew-pr(523,1047);
			text-align: center;
			
			@media (max-width: $mobile) {
				width: 100%;
			}
			
			.image {
				width: aew-pr(372,523);
				margin-left: auto;
				margin-right: auto;
				
				@media (max-width: $mobile) {
					width: aew-pr(298,335);
				}
				
				img {
					border: 0px;
					max-width: 100%;
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					border-radius: 50%;
				}
				
				+ .content {
					margin-top: 25px;
				}
			}
		}
		.right {
			width: aew-pr(474,1047);
			
			@media (max-width: $mobile) {
				width: 100%;
				text-align: center;
				margin-top: 30px;
			}
			
			.headline {
				@media (max-width: $mobile) {
					text-align: center;
				}
				
				+ .text {
					margin-top: 15px;
					
					@media (max-width: $mobile) {
						text-align: center;
					}
				}
			}
			
			.item {
				@include fluid-type('margin-top', 35, 50, 650, 1600);
				
				@media (max-width: $mobile) {
					a {
						justify-content: center;
					}
				}
				
				+ .item {
					@include fluid-type('margin-top', 25, 35, 650, 1600);
				}
				
				&-whatsapp {
					.icon {
						@include fluid-type('width', 35, 45, 650, 1600);
						@include fluid-type('height', 35, 45, 650, 1600);
					}
				}
				
				&-phone {
					.icon {
						@include fluid-type('width', 20, 26, 650, 1600);
						@include fluid-type('height', 20, 26, 650, 1600);
					}
				}
				
				&-email {
					.icon {
						@include fluid-type('width', 24, 32, 650, 1600);
						@include fluid-type('height', 17, 22, 650, 1600);
					}
				}
				
				.icon {
					@include fluid-type('width', 50, 66, 650, 1600);
					display: flex;
					justify-content: flex-start;
					
					svg {
						width: auto;
						height: auto;
						display: block;
					}
				}
			}
		}
	}