
	.settings-content {
		position: relative;
		& > .language-switcher {
			position: absolute;
			right: 0px;
			top: 14px;
			z-index: 5;
		}
		.settings-content-landingpage {
			.modules {
				.field {
					& + .field {
						margin-top: 24px;
					}
				}
			}
		}
		.settings-content-mails {
			.field {
				& + .field {
					margin-top: 24px;
				}
			}
		}
		.block {
			& + .block {
				margin-top: 45px;
			}
		}
	}