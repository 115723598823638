
	@import 'styles/config.scss';
	
	.usp-t1 {
		padding-top: 75px;
		@include fluid-type('padding-bottom', 35, 75, 650, 1600);
		overflow: hidden;
		
		.splide {
			&__arrows {
				@include fluid-type('margin-bottom', 25, 50, 650, 1600);
				display: flex;
				justify-content: center;
			}
			
			&__arrow {
				@include fluid-type('height', 23, 35, 650, 1600);
				@include fluid-type('width', 10, 15, 650, 1600);
				position: relative;
				top: auto;
				transform: none;
				background-color: transparent;
				border-radius: 0;
				opacity: 1;
				
				@media (min-width: 1025px) {
					&:hover {
						opacity: 0.75;
					}
				}
				
				svg {
					width: 100%;
					height: 100%;
					color: #{var(--button1_bg)};
				}
				
				&--prev {
					left: auto;
					margin-right: 25px;
				}
				&--next {
					right: auto;
					margin-left: 25px;
				}
			}
			
			&__track {
				overflow: visible;
			}
			
			&__progress {
				position: relative;
				margin-top: 25px;
				width: aew-pr(400,1047);
				margin-left: auto;
				margin-right: auto;
				
				&:before {
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 0;
					height: 2px;
					width: 100%;
					background: #{var(--button1_bg)};
					opacity: 0.28;
				}
				
				&__bar {
					background: #{var(--button1_bg)};
					height: 2px;
					transition: width 400ms ease;
					width: 0;
				}
			}
		}
		
		.gallery {
			&-items-1,
			&-items-2,
			&-items-3 {
				.splide {
					&__arrows {
						display: none;
					}
					&__list {
						justify-content: center;
					}
					&__progress {
						display: none;
					}
				}
			}
			
			@media (max-width: $mobile) {
				&-items-2,
				&-items-3 {
					.splide {
						&__arrows {
							display: flex;
						}
						&__list {
							justify-content: initial;
						}
						&__progress {
							display: block;
						}
					}
				}
			}
		}
		
		.centercontent1047 {
			.image {
				text-align: center;
				
				+ .content {
					margin-top: 25px;
				}
				
				img {
					border: 0px;
					max-width: 100px;
					max-height: 100px;
					display: inline-block;
				}
			}
			.content {
				text-align: center;
				
				.headline {
					+ .text {
						margin-top: 5px;
					}
				}
			}
		}
	}