
	@import 'styles/config.scss';
	
	.module-footer-gallery-t1 {
		@include fluid-type('padding-top', 10, 30, 650, 1600);
		
		@media (min-width: $tablet) {
			.centercontent1470 {
				max-width: 100% !important;
				width: 100% !important;
			}
		}
		
		.items {
			.item {
				width: 25%;
				
				@media (min-width: $tablet) {
					+ .item {
						padding-left: 10px;
					}
				}
				
				@media (max-width: $tablet) {
					width: 50%;
					
					&:nth-child(2n) {
						padding-left: 5px;
					}
					
					margin-bottom: 4px;
				}
				
				&.headline {
					.inner {
						background-color: #{var(--button1_bg)};
						align-items: center;
						text-align: center;
						height: 100%;
						
						.headline {
							padding: 10px;
							color: #{var(--button1_color)};
						}
					}
				}
				
				.image {
					height: 100%;
					
					img {
						border: 0px;
						max-width: 100%;
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
					}
				}
			}
		}
	}