
	.room-list {
		.headline-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.ant-card {
			margin-top: 30px;
			.ant-card-cover {
				height: 250px;
				position: relative;
				img {
					position: absolute;
					left: 0px;
					top: 0px;
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
		}
	}
	
	.room-create-drawer,
	.room-detail {
		.label {
			margin-bottom: 8px;
		}
		.language-switcher {
			margin-bottom: 30px;
		}
		.headline-container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.not-editable-file-list {
			img {
				max-width: 100%;
				margin-bottom: 15px;
			}
		}
	}