
	@import 'styles/config.scss';
	
	.module-variants-t2 {
		@include fluid-type('padding-top', 70, 110, 650, 1600);
		
		.container {
			@include fluid-type('padding-bottom', 30, 60, 650, 1600);
			
			@media (min-width: $mobile) {
				border-radius: 10px;
			}
			
			.tabs {
				display: flex;
				justify-content: center;
				transform: translateY(-50%);
				
				.button1 {
					margin-left: 3px;
					margin-right: 3px;
					
					&:not(.active) {
						opacity: 0.7;
					}
				}
			}
			
			.elements {
				.element {
					display: none;
					
					&.active {
						display: block;
					}
					
					.content {
						text-align: center;
						
						&.top {
							.variant {
								justify-content: flex-start;
							}
							
							.price,
							.headline,
							.text {
								margin-top: 17px;
							}
							
							.date {
								font-weight: bold;
								margin-top: 10px;
							}
						}
						
						&.bottom {
							@include fluid-type('margin-top', 60, 120, 650, 1600);
							
							.text {
								text-align: left;
								@include fluid-type('margin-top', 15, 30, 650, 1600);
							}
							
							.description {
								@include fluid-type('margin-top', 14, 7, 650, 1600);
							}
							
							.price-table {
								border-radius: 5px;
								margin-top: 50px;
								background-color: #{var(--bgweb_backgroundColor)};
								
								&-inner {
									padding: 30px;
									@media (max-width: 1024px) {
										padding: 15px;
									}
								}
							}
							
							.link-container {
								@include fluid-type('margin-top', 15, 40, 650, 1600);
							}
							
							.validity {
								margin-top: 25px;
							}
						}
					}
					
					.gallery {
						margin-top: 40px;
						position: relative;
						.sketch {
							position: absolute;
							left: 50%;
							bottom: 0px;
							transform: translate(-50%, 50%);
							z-index: 5;
						}
						.fullscreen {
							position: absolute;
							left: 20px;
							bottom: 20px;
							z-index: 5;
							cursor: pointer;
						}
						.lg-react-element {
							display: none;
						}
						.splide {
							&__slide {
								.image {
									height: 0px;
									padding-top: aew-pr(322,464);
									position: relative;
									img {
										border: 0px;
										max-width: 100%;
										display: block;
										position: absolute;
										left: 0px;
										top: 0px;
										width: 100%;
										height: 100%;
										object-fit: cover;
									}
								}
							}
							
							&__arrow {
								@include fluid-type('height', 23, 35, 650, 1600);
								@include fluid-type('width', 10, 15, 650, 1600);
								background-color: transparent;
								border-radius: 0;
								opacity: 1;
								@media (min-width: 1025px) {
									&:hover {
										opacity: 0.75;
									}
								}
								svg {
									width: 100%;
									height: 100%;
									color: #FFFFFF;
								}
								
								&--prev {
									@include fluid-type('left', 12, 20, 650, 1600);
								}
								&--next {
									@include fluid-type('right', 12, 20, 650, 1600);
								}
							}
						}
					}
				}
			}
		}
	}
	
	.lg-container {
		.lg-download {
			display: none;
		}
		.lg-outer img.lg-object {
			max-height: calc(100vh - 150px);
		}
	}